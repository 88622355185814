import {timeFormat, numberFormat} from "@custom/index";
import {conversionAmount} from "@/utils/hooks";

//2022/03/27 王江毅 工单管理 工单列表
export function tableWorkOrderColumn(that) {
    return [
        {
            prop: "workOrderNumber",
            label: "工单编号",
            width: 150
        },
        {
            label: "工单类型",
            width: 150,
            prop:'workOrderTypeName',
        },
        {
            label: "工单状态",
            width: 150,
            prop:'workOrderStatusName',
        },

        {
            label: "房源",
            width: 240,
            // prop:'apartmentName',
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: apartmentName}}});
                        }
                    }
                }, apartmentName);
            }
        },
        {
            label: "租客名字",
            width: 100,
            render: (h, {row}) => {
                const {lessorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "tenant-management", params: {data: {lessorName}}});
                        }
                    }
                }, lessorName);
            }
        },
        {
            prop: "startUserName",
            label: "发起人",
            width: 100,
        },
        // {
        //     prop: "submitter",
        //     label: "发起时间",
        //     width: 100,
        // },
        // {
        //     label: "发起时间",
        //     render: (h, {row}) => {
        //         const {applyTime} = row;
        //         return applyTime ? timeFormat(new Date(row.applyTime)) : "";
        //     }
        // },
        {
            label: "发起时间",
            render: (h, {row}) => {
                const {startTime} = row;
                if(startTime === null) return ''
                return startTime ? timeFormat(new Date(startTime), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
        {
            label: "处理时间",
            render: (h, {row}) => {
                const {handleTime} = row;
                if(handleTime === null) return ''
                return handleTime ? timeFormat(new Date(handleTime), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
        {
            label: "处理记录",
            prop: "handleNum",
            // render: (h, {row}) => {
            //     const {handleNum} = row;
            //     return handleNum > 0 ? "收取" + numberFormat(handleNum / 100) : "退还" + numberFormat((-handleNum) / 100);
            // }
        },
        // {
        //     prop: "handleNum",
        //     label: "备注",
        //     showOverflowTooltip: true,
        // },
    ];
}

export function tableWorkOrderColumnjilu(that) {
    return [
        {
            prop: "handleUserName",
            label: "处理人",
            width: 150
        },
        {
            label: "角色",
            width: 150,
            prop: 'handleRole'
        },
        {
            label: "操作",
            width: 150,
            prop:'operateType',
        },

        {
            label: "操作时间",
            width: 150,
            // prop:'handleDate',
            render: (h, {row}) => {
                const {handleDate} = row;
                if(handleDate === null) return ''
                return handleDate ? timeFormat(new Date(handleDate), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
        {
            prop: "rejectReason",
            label: "驳回原因",
            showOverflowTooltip: true,
        },
    ];
}