<template>
    <section class="work-order-management">
        <div class="top-bar bg-white" style="display:flex;flex-wrap: wrap;">
            <div style="display:flex; width:200px;align-items: center;margin-bottom:10px">
<!--                <div>工单编号</div>-->
                <el-input v-model="formSearch.workOrderNumber" placeholder="请输入工单编号" style="margin: 0 7px; width: 200px" size="small"/>
            </div>
          <div style="display:flex; width:200px;align-items: center;margin-bottom:10px">
            <!--                <div>工单编号</div>-->
            <el-input v-model="formSearch.apartmentOrLessorName" placeholder="请输入房源/租户姓名" style="margin: 0 7px; width: 200px" size="small"/>
          </div>
            <div style="display:flex; width:200px;align-items: center;margin-bottom:10px">
<!--                <div>处理状态</div>-->
                <el-select v-model="formSearch.workOrderType" size="small" placeholder="请选择工单类型" style="margin: 0 7px;; width: 200px">
                    <el-option :value=" '' "  label="全部 "/>
                    <el-option :value="0" label="合同 "/>
                    <el-option :value="1" label="租金  "/>
                    <el-option :value="2" label="离线密码"/>
                </el-select>
            </div>
          <div style="display:flex; width:250px;align-items: center;margin-bottom:10px">
            <!--                <div>处理状态</div>-->
            <el-select v-model="formSearch.workOrderStatus" size="small" placeholder="请选择工单状态" style="margin: 0 7px;; width: 200px">
              <el-option :value=" '' " label="全部 "/>

<!--              工单状态 0：待发起  1：待资产部审核 2：待财务部审核 3：待管理层审核 4：已完成 5：已撤销-->
              <el-option :value="0" label="待发起 "/>
              <el-option :value="1" label="待资产部审核 "/>
              <el-option :value="2" label="待财务部审核"/>
              <el-option :value="3" label="待管理层审核"/>
              <el-option :value="4" label="已完成"/>
              <el-option :value="5" label="已撤销"/>
            </el-select>
          </div>
            <div style="display:flex; width:420px;align-items: center;margin-bottom:10px">
                <div style="width:80px">发起时间</div>
                <el-date-picker v-model="StartAndEndDATE" type="daterange" range-separator="至" start-placeholder="开始日期"
                            style="margin: 0 7px;" end-placeholder="结束日期" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd"/>
            </div>
          <div>
                <el-button type="primary" size="small" style="margin: 0 7px;" @click="handleSearch">查询</el-button>
            </div>
            <div>
                <el-button  @click="handleReset" size="small" style="margin: 0 7px;">重置</el-button>
            </div>
            <!-- <el-button type="primary" size="small" icon="el-icon-download" @click="wordExport">导出</el-button> -->
        </div>
<!--        审批表格-->
        <r-e-table  class="bg-white" ref="orderTableRef" :dataRequest="getWordListTableData" :columns="tableColumn" :query="formSearch"
                   tooltip-effect="light" :height="790" :row-style="rowStyle" :cell-style="cellStyle">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                  <div class="table-tools" >
<!--                       MANAGEMENTAUDIT 管理层审核     ASSETSDEPARTMENTAUDIT 待资产部审核     AUDITBYFINANCEDEPARTMENT 待财务部审核
-->
                    <span v-if="row.workOrderStatusName === '待发起' && getButtonMenu('SPONSOR')" class="table-btn" @click="openDialog('dialogHuanfang',row,'queren')">发起</span>
                    <span v-else-if="row.workOrderStatusName === '待管理层审核' && getButtonMenu('MANAGEMENTAUDIT')"  class="table-btn" @click="openDialog('dialogHuanfang',row,'queren')">审核</span>
                    <span v-else-if="row.workOrderStatusName === '待资产部审核' && getButtonMenu('ASSETSDEPARTMENTAUDIT')"  class="table-btn" @click="openDialog('dialogHuanfang',row,'queren')">审核</span>
                    <span v-else-if="row.workOrderStatusName === '待财务部审核' && getButtonMenu('AUDITBYFINANCEDEPARTMENT')"  class="table-btn" @click="openDialog('dialogHuanfang',row,'queren')">审核</span>
                    <span v-else-if="row.workOrderStatusName === '已撤销' || row.workOrderStatusName === '已完成' " class="table-btn" @click="openDialog('dialogHuanfang',row,'queren')">查看</span>
                    <span v-else class="table-btn" @click="openDialog('dialogHuanfang',row,'off')">查看</span>
                  </div>

                </template>
            </el-table-column>
        </r-e-table>
        <dialog-huanfang ref="dialogHuanfang" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import { tableWorkOrderColumn } from "./data";
    import {
      approvalProcessingRecordGET,

      wordExport
    } from "@/api/work-order-management";
    import { timeFormat } from "@custom/index";
    import { downloadByData } from "@/utils/hooks/download";
    import {buttonCookies} from "@storage/cookies";
    export default {
        name: "approval-process-workorder",
        data() {
            return {
                tableColumn: tableWorkOrderColumn(this),
                formSearch: {
                    status: null,
                    startDate:'',
                    workOrderNumber:null,
                    endDate:'',
                    apartmentOrLessorName:null,
                    workOrderType:null,
                    workOrderStatus:null,
                    state:null,
                  initiationStartTime:null,
                  initiationEndTime:null

                },
                StartAndEndDATE:[],
                state: null,
            };
        },
        mounted() {

        },
        components:{

            dialogHuanfang: ()=>import("./components/dialog-shenpi.vue"),
        },
        methods: {
          clickSubmit(){

          },
            getWordListTableData(params) {
                // 新接口
                return approvalProcessingRecordGET(params);
            },
            handleSearch() {
                this.$refs["orderTableRef"].getTableData(true);
            },
            handleReset() {
                this.formSearch = {status: null,};
                this.StartAndEndDATE = [];
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            openDialog(openName = "", data,type) {
              console.log(data)
                this.$refs[openName].openDialog(data,type);
            },
            wordExport(){
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let {formSearch}=this;
                let paramsData={...formSearch}
                wordExport(paramsData).then(res=>{
                    downloadByData({data: res, filename: `工单管理${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
                    loading.close();
                }).catch(err => {loading.close();});
            },
            rowStyle({row, rowIndex}){
                let {state,wordType} = row;
                console.log('row',row);
                // if(state===2) return {backgroundColor:'#FFF5F5'};
                // if(state===6) return {backgroundColor:'#E5EFFF'};
            //     背景颜色
            },
            cellStyle({row, column, rowIndex, columnIndex}){
                let {workOrderStatusName} = row;
              // if (workOrderStatusName === '待资产部审核' || workOrderStatusName === '待财务部审核' || workOrderStatusName === '待管理员审核') return {color: '#FF0000'};
                if (workOrderStatusName === '待资产部审核' && columnIndex === 2) return {color: '#FF3718'};
                if (workOrderStatusName === '待财务部审核' && columnIndex === 2) return {color: '#FF3718'};
                if (workOrderStatusName === '待管理层审核' && columnIndex === 2) return {color: '#FF3718'};
                if (workOrderStatusName === '待发起' && columnIndex === 2) return {color: '#FF3718'};

            //  字体颜色
            },
            getButtonMenu(menu){
                return JSON.parse(buttonCookies.get()).map(item=>item.url).some(v => v === menu);
            },

        },
        watch:{
          StartAndEndDATE(value) {
            if (value && value.length !== 0) {
              let [startDate, endDate] = value;
              this.formSearch.initiationStartTime = startDate;
              this.formSearch.initiationEndTime = endDate;
            } else {
              this.formSearch.initiationStartTime = null;
              this.formSearch.initiationEndTime = null;
            }
          },
          state(value) {
            if(value) this.formSearch.state = value;
            else this.formSearch.state = null;
          }
        }
    }
</script>

<style lang="scss" scoped>
    .work-order-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
            }
        }
    }
</style>
